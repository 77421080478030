<template>
  <div class="misc-wrapper">
    <b-row class="auth-inner m-0">
      <b-link class="brand-logo">
        <logo />
        <h2 class="brand-text text-primary ml-1">{{ appName }}</h2>
      </b-link>

      <b-navbar-nav class="nav align-items-center ml-auto absolute-locale">
        <locale></locale>
      </b-navbar-nav>
    </b-row>

    <div class="misc-inner p-2 p-sm-3">
      <div class="w-100 text-center">
        <h2 class="mb-1">
          {{ $t("pageNotAuthorized.title") }}
        </h2>
        <p class="mb-2">
          {{ $t("pageNotAuthorized.subTitle") }}
        </p>
        <b-button
          variant="primary"
          class="mb-1 mr-1 btn-sm-block"
          :to="loginRoute()"
        >
          {{ $t("button.back") }}
        </b-button>
        <b-button
          variant="danger"
          class="mb-1 btn-sm-block"
          @click="logout()"
          >{{ $t("button.logout") }}</b-button
        >
        <b-img fluid :src="imgUrl" alt="Not authorized page" />
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { BLink, BImg, BButton, BRow, BNavbarNav } from "bootstrap-vue";
import Logo from "@core/layouts/components/Logo.vue";
import store from "@/store/index";
import { getHomeRouteForLoggedInUser } from "@/auth/utils";
import { $themeConfig } from "@themeConfig";
import { logout } from "@/auth/utils";
import Locale from "@/layouts/components/app-navbar/Locale.vue";

export default {
  components: {
    BRow,
    BLink,
    BImg,
    BButton,
    BNavbarNav,
    Logo,
    Locale,
  },
  data() {
    return {
      downImg: require("@/assets/images/pages/not-authorized.svg"),
    };
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.downImg = require("@/assets/images/pages/not-authorized-dark.svg");
        return this.downImg;
      }
      return this.downImg;
    },
  },
  methods: {
    loginRoute() {
      const user = JSON.parse(localStorage.getItem("userData"));
      let route = getHomeRouteForLoggedInUser(
        user ? "admin" : null,
        null,
        user.ability
      );
      return route;
    },
    logout() {
      logout();
      this.$router.push({ name: "login" });
    },
  },
  setup() {
    // App Name
    const { appName } = $themeConfig.app;
    return {
      appName,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-misc.scss";
</style>
